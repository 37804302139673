.fio {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    color: #000000;
    margin-bottom: 40px;
}
.fio > span:nth-child(2n) {
    margin: 0 5px;
}

.fio > div {
    font-weight: 400;
    margin-top: 20px;
}
