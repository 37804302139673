.btnLink {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0077FF;
    transition: .5s;
    margin-bottom: 50px;
    cursor: pointer;
}

.qrWrapper {
    margin-bottom: 50px;
}

.qrImg {
    width: 100%;
    height: auto;
    margin-left: -15px;
}

.qrContainer {
    display: grid;
    place-items: center;
}
