form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
}

h1 {
    font-weight: 100;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
    background: #0e101c;
    max-width: 400px;
    margin: 0 auto;
}

p {
    color: #bf1650;
}

p::before {
    display: inline;
    content: "⚠ ";
}

input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #B5BBC2;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}

label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
}

button[type="submit"],
input[type="submit"] {
    background: #0077FF;
    letter-spacing: 1px;
    border: none;
    border-radius: 5px;
    margin-top: 40px;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
    padding: 20px 0;
    font-family: 'Roboto', sans-serif;
}

button[type="submit"]:hover,
input[type="submit"]:hover {
    background: #135ee4;;
    transition: ease-in-out .3s;
}

button[type="submit"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
}

input:disabled {
    opacity: 0.4;
}

input[type="button"]:hover {
    transition: 0.3s all;
}

button[type="submit"],
input[type="button"],
input[type="submit"] {
    -webkit-appearance: none;
}

