.myBtn {
    background: #0077FF;
    border-radius: 5px;
    margin: 20px 0 30px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;
}

.myBtn:hover {
    background: #135ee4;
    transition: ease-in-out .3s;
}
