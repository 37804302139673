.social  {
    margin-bottom: 40px;
}

.social div img {
    margin-right:15px;
}

.social div {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    display: flex;
}

.ws {
    margin-bottom:20px;
}

.social > div {
    margin-top: 20px;
}
