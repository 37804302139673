.photo img {
    border-radius: 5px;
}

.photo {
    width: 350px;
    height: 350px;
    overflow: hidden;
    position: relative;
}

.photo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
