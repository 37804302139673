.postBig {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: #000000;
    margin-bottom: 8px;
}
.postSmall {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #000000;
    margin-bottom: 50px;
}

.banner {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    background: #EAEAEA;
    border-radius: 5px;
    padding:60px;
    margin-bottom: 15px;
    justify-content: center;
}

footer  {
    margin-top:40px;
}
footer img {
    margin-bottom: 15px;
}
.copyright  {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: #000000;
}

footer  a {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #0077FF;
    text-decoration: none;
}
