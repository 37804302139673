.contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
}
.content {
    width:350px;
    max-width:100%;
}

.photoBlock {
    width: 350px;
    max-width:100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    padding-top: 40px;
    margin-bottom: 60px;
}

.photo img,
.logo img {
    width:100%;
    height: auto;
}
