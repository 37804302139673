.wrapper {
    width: 100%;
    margin: 30px 0;
}

.wrapper > img {
    width: 235px;
    margin-bottom: 15px;
}

.wrapper > a {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-decoration: none;
    color: #07f;
}
